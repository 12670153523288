import { useEffect } from 'react';

const metaTags = {
  ita: {
    portfolioMain: {
      title: "Michele Tore | Portfolio",
      description: "Il sito web ufficiale di Michele Tore, un ingegnere civile con vasta esperienza in progetti di infrastrutture. Scopri il suo portfolio di progetti, che include una varietà di lavori di ingegneria civile, dalle costruzioni alle infrastrutture idrauliche.",
    },
    home: {
      title: "Michele Tore - Ingegnere Civile",
      description: "Michele Tore, ingegnere civile. Scopri di più sui suoi progetti e competenze.",
    },
    curriculum: {
      title: "Michele Tore | Curriculum",
      description: "Esplora il curriculum di Michele Tore, ingegnere civile, con dettagli sui suoi studi e la sua esperienza lavorativa.",
    },
    contacts: {
      title: "Michele Tore | Contatti",
      description: "Contatta Michele Tore, ingegnere civile. Trova tutte le informazioni di contatto qui.",
    }
  },
  spa: {
    portfolioMain: {
      title: "Michele Tore | Portfolio",
      description: "El sitio web oficial de Michele Tore, un ingeniero civil con amplia experiencia en proyectos de infraestructura. Descubre su portafolio de proyectos, que incluye una variedad de trabajos de ingeniería civil, desde la construcción hasta la infraestructura hidráulica.",
    },
    home: {
      title: "Michele Tore - Ingeniero Civil",
      description: "Michele Tore, ingeniero civil. Aprende más sobre sus proyectos y experiencia.",
    },
    curriculum: {
      title: "Michele Tore | Curriculum",
      description: "Explora el currículum de Michele Tore, ingeniero civil, con detalles sobre su educación y experiencia laboral.",
    },
    contacts: {
      title: "Michele Tore | Contactos",
      description: "Contacta a Michele Tore, ingeniero civil. Encuentra toda la información de contacto aquí.",
    }
  },
  en: {
    portfolioMain: {
      title: "Michele Tore | Portfolio",
      description: "The official website of Michele Tore, a civil engineer with extensive experience in infrastructure projects. Discover his portfolio of projects, which includes a variety of civil engineering works, ranging from construction to hydraulic infrastructure.",
    },
    home: {
      title: "Michele Tore - Civil Engineer",
      description: "Michele Tore, civil engineer. Learn more about his projects and expertise.",
    },
    curriculum: {
      title: "Michele Tore | Curriculum",
      description: "Explore Michele Tore's curriculum, a civil engineer, detailing his education and work experience.",
    },
    contacts: {
      title: "Michele Tore | Contacts",
      description: "Contact Michele Tore, civil engineer. Find all the contact information here.",
    }
  }
};

const MetaTag = ({ language, page }) => {
  const currentMeta = metaTags[language]?.[page] || metaTags['en'][page];

  useEffect(() => {
    // Aggiorna il titolo della pagina
    document.title = currentMeta.title;
    
    // Aggiorna l'attributo lang dell'elemento html
    document.documentElement.lang = language;
    
    // Aggiorna i meta tag
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute('content', currentMeta.description);
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = currentMeta.description;
      document.head.appendChild(meta);
    }

    const ogTitleMetaTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleMetaTag) {
      ogTitleMetaTag.setAttribute('content', currentMeta.title);
    } else {
      const meta = document.createElement('meta');
      meta.setAttribute('property', 'og:title');
      meta.content = currentMeta.title;
      document.head.appendChild(meta);
    }

    const ogDescriptionMetaTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionMetaTag) {
      ogDescriptionMetaTag.setAttribute('content', currentMeta.description);
    } else {
      const meta = document.createElement('meta');
      meta.setAttribute('property', 'og:description');
      meta.content = currentMeta.description;
      document.head.appendChild(meta);
    }
  }, [currentMeta, language]);

  return null;
};

export default MetaTag;
