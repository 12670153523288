import { createContext, useState } from 'react';
import Languages from '../components/Languages';

export const lancontext = createContext();

export function LancontextProvider({ children }) {
  const { ita } = Languages();
  const [lan, setLan] = useState(ita);

  console.log('LancontextProvider initialized with language:', lan);

  return (
      <lancontext.Provider value={{ lan, setLan }}>
          {children}
      </lancontext.Provider>
  );
}

