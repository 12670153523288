import { Link } from "react-router-dom";

export default function CV_UK() {
  return (
    <section>
      <h2><u><i>Profile</i></u></h2>
        <i>An expert and consistent assistant civil engineer, working more than 20 years designing infrastructures 
and construction projects. I have a wide and international civil engineering experience, mostly in drainage,
highways and construction projects, in office and on site, as employee and freelance. I enjoy working in 
professional teams for the interchange of competences and life experience.
I am looking for a solid company that takes advantage from my engineering knowledge, working polyvalency and commitment.
</i>
      <h2><u><i>Employment History</i></u></h2>
        <ul>
          <li>
            <h3>CAD DESIGNER and REVIT MODELLER – Self-employed – Apr 2020 – current</h3>
            <ul>
              <li>
                <b>Contract : GTDS electric – Oct 2022 – Jan 2024</b>
                <p>Designing the civil works for electric substation equipment (foundations, roads, drainage, etc). AutoCAD</p>
              </li>
              <li>
                <b>Supply teacher and teacher : PK Ed., Balfor, PET-xi – Feb 2022 – Sep 2022</b>
              </li>
              <li>
                <b>Various engineering consultancies: 2020 – 2022 </b>
                <p>Drawing architectural renovations and highways projects. AutoCAD, REVIT, Excel</p>
              </li>
            </ul>
          </li>

          <li>
            <h3>ENGINEERS’ TEAM LEADER – Geosynthetics Ltd – 2019-20 (Sep-Feb)</h3>
            <i>The company sells geosynthetical materials for civil engineering to reduce the use of stones and concrete
            (embankments, platforms, parking, soil separation, drainage, waterproofing, etc).</i>
            <p>Under the principal engineer, I managed the engineers’ team in redacting specific calculations and 
drawings to advise the client upon the correct product, its installation details and quantities.
During few months, I delegated the tasks, controlled and automatized three types of calculation and 
implemented 3 new KPIs for checking and measuring the team’s performance. In trimestral averages, the 
productivity past from 1.22 to 1.50 tasks per person per day and the tasks completed on time from 76% to 
94% (with last week records of 1.56 and 100%).
Designed architectural renovation projects. - AutoCAD, Excel
            </p>
          </li>

          <li>
            <h3>CAD DESIGNER – Self-employer – 2019 Feb – Aug</h3>
            <i>(summarised in the above experience plus Revit usage)</i><br/>
          </li>

          <li>
            <h3>CAD DESIGNER – Adcock associates adp – 2018 (Nov-Dec)</h3>
            <i>Architectural consultancy specialised in petrol stations with their retailing spaces and villas’ gardens.</i>
            <p>Designed architectural plans, elevations, and sections for Planning Applications (with vehicle path 
              tracking) and schemes for client’s approvals.</p>
          </li>

          <li>
            <h3>CAD ENGINEER – BMV JV (M5 Oldbury Viaduct) / Socotec – 2018 (Jun-Oct)</h3>
              <i>In the joint venture project to renovate a highway viaduct, my company detected the superficial and deep
              defects of the decks’ concrete, analysed them and delivered the reports to the design office.</i>
              <p>Redacted topographical defects’ plans, chemical and geophysical results and improved the process with 
five VBA codes.
Used packages: AutoCAD 2019, Excel 2016, VBA
              </p>
          </li>

          <li>
            <h3>PROJECT DESIGNER and SUPERVISOR – Self-employer – 2012 – 2017</h3>
            TASKS: DESIGNER AND CAD TECHNICIAN - ESTIMATOR - SITE SUPERVISOR<br/><br/>
            <i>In three periods, as a freelance, completed and collaborated in various kinds of projects:</i><br/>
            <ul>
              <li>Residential dwellings</li>
              <li>Drainage calculations</li>
              <li>H&S coordination</li>
              <li>a Bridge in concrete</li>
              <li>a Highway</li>
              <li>a Steel warehouse</li>
              <li>a Health clinic</li>
              <li>Judicial evaluations</li>
              <li>Roof PV installations</li>
              <li>ADR administrations</li>
              <li>Cadastral requirements (Land Register)</li>
            </ul>
            <p>Used packages: Autocad 2D e 3D, Revit, Excel, Word, VBA, Primus and Mosaico (estimation), cadastral 
            packages, Ms-Project, Primavera, others.</p>
          </li>

          <li>
            <h3>TECHNICAL DIRECTOR – FSG Instalaciones Y Servicios – 2011 – 2012</h3>
            TASKS: DESIGNER AND CAD TECHNICIAN - ESTIMATOR - SITE SUPERVISOR<br/><br/>
            <i>PMI of installation and maintenance of water and gas piping.</i><br/>
            <p>Realised the first internal costs’ analysis ; Built the prices’ list ; Opened commercial relations with 3 water 
companies of possible clients ; Bidden various offers ; Executed 4 works. 
Used packages: AutoCAD, Word, Excel, Presto (estimation)
            </p>
          </li>

          <li>
            <h3>PROJECT DESIGNER and SUPERVISOR – Self-employer – 2010 – 2011</h3>
            <i>(summarised in the above experience)</i><br/>
          </li>

          <li>
            <h3>SITE SUPERVISOR – Aquagest Levante (AGBAR group) – 2007 – 2010</h3>
            <i>New drainage, wastewater and piping projects in inhabited streets for the mayor Spanish company of the 
cycle of water. Specific works: groundworks; shoring; nets’ crossings; concrete works; piping in 
concrete, plastic, precast and fibreglass; valves; tunnels; electricity, lighting and signalling on 
the streets, paving; etc.).
            </i><br/>
            <ul>
              <li>SITE SUPERVISOR (main charge: 24 months): Completed 6 projects (total 1.8km, value €6m.)</li>
              <li>PROJECT MANAGER (4 ms) : Managed 4 works (total 500mt , value €600,000). </li>
              <li>SERVICE ENGINEER (6 ms) : Driven the service of water, sewage and drainage of a 12,000 inhabitants’ town</li>
              <li>ESTIMATOR AND CAD DESIGNER (saved time) : 40 projects Designed in autonomy and in Team.</li>
            </ul>
            <p>Used packages: AutoCAD, Excel, Word, Lotus, Outlook, Arquímedes (estimation)
            </p>
          </li>

          <li>
            <h3> PROJECT DESIGNER and SUPERVISOR – Self-employer – 2001 – 2007</h3>
            <i>(summarised in the above experience)</i><br/>
          </li>
        </ul>
        
      <h2><u><i>Key Skills</i></u></h2>
        <h3>Technical</h3>
        <ul>
          <li>civil engineering</li>
          <li>residential buildings</li>
          <li>project design</li>
          <li>3d modelling</li>
          <li>site supervising</li>
          <li>structures</li>
          <li>concrete</li>
          <li>steel</li>
          <li>ground works</li>
          <li>drainage</li>
          <li>canals</li>
          <li>piping</li>
          <li>CAD expert</li>
          <li>cost estimating</li>
          <li>project planning</li>
          <li>residential supply</li>
        </ul>

        <h3>Computer (advanced user)</h3>
        <ul>
          <li>Design : Revit, AutoCAD</li>
          <li>Project Planning : Ms-Project , Primavera , Excel (GANTT, PERT, assigning resources)</li>
          <li>Cost Estimate : Mosaico, Primus (Ita) , Arquimedes , Presto (Spa) , Estimate , Excel</li>
          <li>Office : Excel, Access, Word, VBA, PowerPoint, Project, Outlook, Teams</li>
          <li>Project Planning : Ms-Project , Primavera , Excel</li>
        </ul>
        <p>AutoCAD (20+ years): advanced user of civil and construction projects.</p>
        <p>Revit (2 yrs): intermediate user. Building modelling, construction details, material analysis, printing.</p>
        <p>Civil 3D (1 yr): intermediate user. Creation and management of Surfaces, alignments, profiles, assemblies, 
        corridors, Volumes fills and cuts, piping. Visualisation and printing</p>

        <h3>Languages</h3>
        <ul>
          <li>English (fluent)</li>
          <li>Italian (native)</li>
          <li>Spanish (fluent)</li>
        </ul>

      <h2><u><i>Education & Training</i></u></h2>
        <ul>
          <li>CIDORI, Birmingham, UK – BTEC lev. 1 and CSCS card – 50hrs</li>
          <li>Engineers’ Society, Oristano, Ita – occupational safety and health (EU laws) – 40hrs – Update license</li>
          <li>BEIS Department, Spain – Civil engineer – Approval</li>
          <li>Muprespa, Valencia, Spain. – occupational safety and health – 50hrs</li>
          <li>IAL and others, Oristano, Italy – entrepreneurial management – 100hrs</li>
          <li>University of Cagliari, Italy – H&S, Occupational Health and Safety– 120hrs – License</li>
          <li>University of Cagliari, Italy – MEng Environmental Engineering – 5 yrs – Master’s Degree</li>
        </ul>

      <h2><u><i>Various</i></u></h2>
        <Link to='/portfolio'>Portfolio</Link>
        <p>References , Full UK driving license , UK Settled Status , Enhanced DBS are available upon request</p>
    </section>
  )
}
