import './Home.css';
import mainImage from '../assets/img/michele2.png';
import { useNavigate } from 'react-router-dom';

export default function Home({ language }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/contacts');
  }
  return (
    <main className='main-content'>
      <section className='main-section main-section-img'>
        <img src={mainImage} alt='michele' className='main-image' />
      </section>
      <section className='main-section main-section-text'>
        <h2>Michele Tore</h2>
        <p>{language.lan.intro}</p>
        <button className='buttonCta' onClick={handleClick}>{language.lan.cta1}</button>
      </section>
    </main>
  );
}
