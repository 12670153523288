import {useContext, useState} from 'react'
import { lancontext } from '../context/lancontext'
import itaFlag from '../assets/img/ita.png'
import ukFlag from '../assets/img/uk.png'
import spaFlag from '../assets/img/spa.png'
import hamburger from '../assets/img/hamburger.png'
import Languages from '../components/Languages';
//style
import './Navbar.css'
import { Link } from 'react-router-dom'

export default function Navbar() {
    const language = useContext(lancontext);
    const [hamburgerOpen, setHamburgerOpen] = useState(false)
    const {ita, uk, spa} = Languages();
    const handleFlagClick = (e, chosenLan) => {
        document.querySelector('.selectedFlag').classList.remove('selectedFlag')
        e.target.classList.add('selectedFlag')
        chosenLan === 'ita'?
        language.setLan(ita):
        chosenLan === 'uk'?
        language.setLan(uk):
        language.setLan(spa)
        console.log(`You selected ${chosenLan} language`)
    }
    const handleMenuClick = (e) => {
        document.querySelector('.selectedMenu').classList.remove('selectedMenu')
        e.target.classList.add('selectedMenu')
    }
    const handleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen)
    }
  return (
    <nav className='navbar'>
        <div className='logo-container'>
            <h1>Michele Tore</h1>
            <p>{language.lan.job}</p>
        </div>
        <div className='link-lang-container'>
            <ul className='link-ul'>
                <li>
                    <img 
                    className='flags selectedFlag'
                    src={itaFlag} 
                    alt='bandiera italiana' 
                    onClick={(e) => handleFlagClick(e,'ita')}
                    />
                </li>
                <li>
                    <img 
                    className='flags'
                    src={ukFlag} 
                    alt='english flag' 
                    onClick={(e) => handleFlagClick(e, 'uk')}
                    />
                </li>
                <li>
                    <img 
                    className='flags'
                    src={spaFlag} 
                    alt='bandera espanol' 
                    onClick={(e) => handleFlagClick(e, 'spa')}
                    />
                </li>
            </ul>
            <ul  className='link-ul classic-menu'>
                <li><Link to='/' className='selectedMenu' onClick={handleMenuClick}>Home</Link></li>
                <li><Link to='/curriculum' onClick={handleMenuClick}>Curriculum</Link></li>
                <li><Link to='/portfolio' onClick={handleMenuClick}>Portfolio</Link></li>
                <li><Link to='/contacts' onClick={handleMenuClick}>{language.lan.link}</Link></li>
            </ul>
            <img 
                src={hamburger} 
                alt='hamburger menu' 
                className='hamburger-menu-icon'
                onClick={handleHamburger}
            />
            {hamburgerOpen && 
            (<ul  className='hamburger-menu' onClick={() => setHamburgerOpen(false)}>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/curriculum'>Curriculum</Link></li>
                <li><Link to='/portfolio'>Portfolio</Link></li>
                <li><Link to='/contacts'>{language.lan.link}</Link></li>
            </ul>)
            }
        </div>
    </nav>
  )
}
