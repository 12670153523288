
export default function Languages() {
    const ita = {
        "lan": "ita",
        "job": "Ingegnere Civile",
        "link": "Contatti",
        "cta1": "Contattaci!",
        "intro": `Ingegnere con 20 anni di esperienza, in Italia e all’estero, nella progettazione e costruzione di infrastrutture di ingegneria civile, in particolare di idraulica, strade ed edifici. L’esperienza maturata mi consente di dare un contributo sostanziale nella progettazione e nella direzione lavori. Cerco una impresa organizzata che sappia trarre beneficio dalle mie conoscenze, versatilità lavorativa e dedizione.`
    }
    const uk = {
        "lan": "uk",
        "job": "Civil Engineer",
        "link": "Contacts",
        "cta1": "Contact us!",
        "intro": `An expert and consistent CAD designer, working more than 20 years designing infrastructures and construction projects. I have a wide and international civil engineering experience, mostly in drainage, highways and construction projects, in office and on site, as employee and also freelance. I enjoy working in professional teams for the interchange of competences and life experience.

        I am looking for a solid company that takes advantage from my engineering knowledge, working polyvalency and commitment.`
    }
    const spa = {
        "lan": "spa",
        "job": "Ingeniero Civil",
        "link": "Contactos",
        "cta1": "¡Contáctanos!",
        "intro": `Ingeniero con 20 años de experiencia internacional en el diseño de proyectos de infraestructuras civiles y construcción , en particular alcantarillado, vías y edificación. La experiencia adquirida me permite realizar una aportación sustancial tanto como Modelador BIM, Diseñador de Proyectos o Asistente del Director de Obra. Busco una empresa organizada que sepa sacar beneficio de mis conocimientos, polivalencia laboral y dedicación.`
    }
  return {ita, uk, spa}
}
