import CV_ITA from "./Curriculum_sub/CV_ita"
import CV_SPA from "./Curriculum_sub/CV_spa"
import CV_UK from "./Curriculum_sub/CV_uk"
import { useFetch } from "../hooks/useFetch"
//style
import './Curriculum.css'

export default function Curriculum({language}) {
  const currentLang = language.lan.lan
  const { data, isPending, error } = useFetch('/CV/cv.json')
  console.log(data)
  
  return (
    <section className="curriculum">
      <div className="curriculum-header">
        <h1>CURRICULUM</h1>
        {error && null}
        {isPending && <p>Loading...</p>}
        {data && 
          (
          <div>
          <a href={data[currentLang]} download aria-label="download pdf">
            {
              currentLang === 'ita'? "Download pdf aggiornato" :
              currentLang === 'spa'? "Descargar PDF actualizado" : 
              "Download updated PDF"
            }
          </a>
          {currentLang === 'ita' && (
            <a 
              href="https://europa.eu/europass/eportfolio/screen/share/4529af54-0a7b-4b13-a657-24bd411bab81" 
              download 
              aria-label="download CV europass"
              target="_blank"
              rel="noreferrer"
            >
              CV europass
            </a>
          )}
          </div>
          )
        }
      </div>
      {language.lan.lan === 'ita' && <CV_ITA />}
      {language.lan.lan === 'uk' && <CV_UK />}
      {language.lan.lan === 'spa' && <CV_SPA />}
    </section>
  )
}
