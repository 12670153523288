import './Contacts.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

export default function Contacts({language}) {
  return (
    <div className='contatti'>
      <h2>{language.lan.link.toUpperCase()}</h2>
      <FontAwesomeIcon icon={faLinkedin} className='social-icon'/>
      <a 
        href='https://www.linkedin.com/in/michele-tore-3927224'
        target='_blank'
        rel='noreferrer'
      >LinkedIn</a>
      <br/>
      <FontAwesomeIcon icon={faEnvelope} className='social-icon'/>
      <a href='mailto:ing@michele-tore.it'>Email</a>
    </div>
  )
}
