import { Link } from "react-router-dom";

export default function CV_SPA() {
  return (
    <section>
      <h2><u><i>Perfil</i></u></h2>
      <p>Ingeniero con 20 anos de <strong>experiencia internacional</strong> en el diseño de proyectos de <b>infraestructuras civiles y construction</b>, en particular <b>alcantarillado, vías y edificación</b>. La experiencia adquirida me permite realizar una aportación sustancial tanto como <b>Modelador BIM, Diseñador de Proyectos</b> o <b>Asistente del Director de Obra</b>. Busco una empresa organizada que sepa sacar beneficio de mis conocimientos, polivalencia laboral y dedicación.</p>

      <h2><u><i>Principales áreas de experiencia</i></u></h2>
      <ul>
        <li><h3>Dibujo técnico:</h3>
            <ul>
                <li>Revit, AutoCAD, Civil 3D, ArcGIS</li>
            </ul>
        </li>
        <li><h3>Contabilidad de proyecto:</h3>
            <ul>
                <li>Estimación de costes y Presupuestos, redacción de Informes y análisis de gastos</li>
                <li>Arquímedes, Presto, Mosaico, Primus, Excel</li>
            </ul>
        </li>
        <li><h3>Gestión de Personal:</h3>
            <ul>
                <li>Coordinación de recursos internos</li>
                <li>Elección y contratación de proveedores externos</li>
                <li>Capacitación y desarrollo del personal en temas técnicos</li>
                <li>Gestiòn del rendimiento</li>
            </ul>
        </li>
        <li><h3>Gestión de proyectos:</h3>
            <ul>
                <li>Estimación y seguimiento de costes, asignación de recursos, supervision de obra, pagos y facturación</li>
                <li>Ms-Project, Primavera, Excel (GANTT, PERT)</li>
            </ul>
        </li>
      </ul>

      <h2><u><i>Principales experiencias profesionales</i></u></h2>
      <ul>
        <li><h3>2020 - HOY: BIM specialist y diseñador CAD - Freelance, Italia y Reino Unido</h3>
            <ul>
                <li>Diseño de obras civiles para subestaciones eléctricas, sistemas BESS, reformas vivienda</li>
                <li>Tablas de proyectos: Plantas, secciones, alzados, detalles constructivos, marco topográfico y georreferenciación, rótulos, notas y leyenda, codificación de proyectos</li>
                <li>Revit: modelo 3D, familias, tablas cuantitativas, maquetación e impresión ejecutiva</li>
            </ul>
        </li>

        <li><h3>2019 - 2020: Líder de equipo y Diseñador CAD - Geosynthetic, RU</h3>
            <p>La empresa se ocupa de materiales geosintéticos para ingeniería civil para reducir el uso de áridos externos y hormigón (terraplenos, plataformas, estacionamientos, separación de suelos, drenaje, impermeabilización, etc.)</p>
            <ul>
                <li>Coordinación del equipo de ingeniería en la redacción de cálculos y dibujos, asignación de actividades y seguimiento del logro de los KPI individuales y del proyecto</li>
                <li>Interacción con el cliente para la creación de la mejor solución de diseño</li>
                <li>Soporte para la ejecución de proyectos con actividades de cálculo y dibujo de AutoCAD</li>
            </ul>
        </li>

        <li><h3>2010 - 2017: Ingeniero civil y medioambiental - autónomo, Italia</h3></li>
            <ul>
                <li><h4>Principales proyectos realizados:</h4>
                    <ul>
                        <li>Viviendas, parques públicos, presas en tierra, instalaciones eléctricas y de iluminación, fotovoltaicas, contra-incendios, aire acondicionado</li>
                    </ul>
                </li>
                <li><h4>Principales actividades:</h4>
                    <ul>
                        <li>Contacto con clientes, factibilidad legal, técnica y económica, diseño preliminar, definitivo y ejecutivo, cálculos especializados, presupuesto, supervisión Triángulo de hierro (TAC), pagos parciales, factura final</li>
                    </ul>
                </li>
                <li><h4>Tramitación de expedientes:</h4>
                    <ul>
                        <li>coordinación de seguridad, prácticas para la Directiva Europea de Maquinaria, prácticas ADR, documentación cadastral</li>
                    </ul>
                </li>
            </ul>

        <li><h3>2007 - 2010: Auxiliar de Dirección de Obra - Aquagest Levante, Valencia</h3>
        <p>Grupo multinacional líder en el ciclo integral del agua adquirido por el GRUPO SUEZ. Diseño y construcción de sistemas de agua potable, alcantarillado y pluviales, con reconstrucción del firme al finalizar. </p>
            <p>Actividades realizadas:</p>
            <ul>
                <li>Auxiliar de Dirección de Obra 6 obras (total 1,8km–6m€–10 trabajadores en obra)</li>
                <li>Jefe de proyecto (5 meses) 4 proyectos (total 500m – 600.000€ – 10 trres. en obra)</li>
                <li>Responsable del servicio (6 meses) de distribución de agua potable (municipio de 12.000 habitantes)</li>
                <li>Diseñador (4 meses) de apoyo y feedback, en los recesos de obra</li>
            </ul>
        </li>

        <li><h3>2001 - 2006: Ingeniero civil y medioambiental - autónomo, Italia</h3>
            <ul>
                <li>Creación de proyectos residenciales y viales y, en colaboración con otras empresas, un puente, un policlínico, una vía suburbana y peritaciones judiciales</li>
                <li>Contacto con clientes, factibilidad legal, técnica y económica, diseño preliminar, definitivo y ejecutivo, cálculos especializados, cálculo métrico, Triángulo de Hierro TAC, pagos, factura final</li>
                <li>Coordinación de seguridad, prácticas cadastrales</li>
            </ul>
        </li>
      </ul>

      <h2><u><i>Educación</i></u></h2>
      <ul>
        <li>2012: Reconocimiento del título de ICCP en España, Min. Fomento</li>
        <li>2005: Curso de Emprendimiento y Plan de Negocios, IAL, Oristano</li>
        <li>1999: Maestría en Ingeniería Ambiental, Universidad de Cagliari, Cagliari</li>
      </ul>

      <h2><u><i>Habilidades</i></u></h2>
      <ul>
        <li>Curiosidad</li>
        <li>Fiabilidad</li>
        <li>Atención a los detalles</li>
        <li>Trabajo en equipo</li>
      </ul>

      <h2><u><i>Idiomas</i></u></h2>
      <ul>
        <li>Italiano: nativo</li>
        <li>Inglés: fluido (C1-2)</li>
        <li>Español: bilingüe (C2)</li>
        <li>Catalán y francés: básico</li>
      </ul>

      <h2><u><i>Otro info</i></u></h2>
      <ul>
        <li><Link to='/portfolio'>Portfolio</Link></li>
        <li>Carné de conducir</li>
        <li>Coche propio</li>
      </ul>
    </section>
  )
}
