import { Link } from "react-router-dom";

export default function CV_ITA() {
  return (
    <section>
      <h2><u><i>Profilo</i></u></h2>
      <p>Ingegnere con 20 anni di <strong>esperienza</strong>, in Italia e all’estero, nella progettazione e costruzione di <strong>infrastrutture</strong> di <strong>ingegneria civile</strong>, in particolare di <strong>idraulica, strade ed edifici</strong>. L’esperienza maturata mi consente di dare un contributo sostanziale nella progettazione e nella direzione lavori. Cerco una impresa organizzata che sappia trarre beneficio dalle mie conoscenze, versatilità lavorativa e dedizione.</p>

      <h2><u><i>Principali aree di competenza</i></u></h2>
      <ul>
        <li><h3>Disegno tecnico:</h3>
            <ul>
                <li>Revit, AutoCAD, Civil 3D, ArcGIS</li>
            </ul>
        </li>
        <li><h3>Contabilità di progetto:</h3>
            <ul>
                <li>Stima dei costi e previsione di spesa, rendicontazione e analisi</li>
                <li>Mosaico, Primus, Arquimedes, Presto, Excel</li>
            </ul>
        </li>
        <li><h3>People management:</h3>
            <ul>
                <li>Coordinamento di risorse interne</li>
                <li>Scelta e ingaggio fornitori esterni</li>
                <li>Formazione e sviluppo del personale su tematiche tecniche</li>
                <li>Performance management</li>
            </ul>
        </li>
        <li><h3>Project management:</h3>
            <ul>
                <li>Roadmap di progetto, stima e monitoraggio costi, assegnazione risorse, SAL</li>
                <li>Ms-Project, Primavera, Excel (GANTT, PERT)</li>
            </ul>
        </li>
      </ul>

      <h2><u><i>Principali esperienze professionali</i></u></h2>
      <ul>
        <li><h3>2020 - OGGI: MODELLATORE REVIT, DISEGNATORE CAD - Freelance, Italia & UK</h3>
            <ul>
                <li>Progettazione di opere civili per sottostazioni elettriche, sistemi BESS, ristrutturazioni;</li>
                <li>Tavole di progetto: Piante, sezioni, prospetti, dettagli costruttivi, inquadramento topografico e georeferenziazione, etichette, note e legenda, codifica di progetto;</li>
                <li>Revit: Modello 3D, famiglie componenti, tabelle quantitative, impaginazione e stampa esecutiva.</li>
            </ul>
        </li>

        <li><h3>2019 - 2020: TEAM LEADER E CAD DESIGNER - Geosynthetic, UK</h3>
            <p>L’azienda si occupa di materiali geosintetici per l’ingegneria civile per ridurre l'uso di inerti alloctoni (terrapieni, piattaforme, separazione del suolo, drenaggio, impermeabilizzazione, parcheggi, ecc.);</p>
            <ul>
                <li>Coordinamento del team di ingegneri nella redazione di calcoli e disegni, assegnazione attività e monitoraggio del raggiungimento dei KPI di progetto e individuali;</li>
                <li>Interfaccia con il cliente per la realizzazione della migliore soluzione progettuale;</li>
                <li>Supporto all'esecuzione del progetto con attività di calcolo e disegno AutoCAD.</li>
            </ul>
        </li>

        <li><h3>2010 - 2017: INGEGNERE CIVILE E AMBIENTALE - Autonomo, Italia</h3></li>
            <ul>
                <li><h4>Principali progetti realizzati:</h4>
                    <ul>
                        <li>Residenze, parchi pubblici, sbarramenti collinari, impianti elettrici e di illuminazione, PV, anti-incendio, HVAC</li>
                    </ul>
                </li>
                <li><h4>Principali attività progettuali:</h4>
                    <ul>
                        <li>Contatto con i clienti, fattibilità legale, tecnica ed economica, progetto preliminare, definitivo ed esecutivo, calcoli specialistici, computo metrico, supervisione TQC, SAL, fattura finale;</li>
                    </ul>
                </li>
                <li><h4>Adempimenti burocratici:</h4>
                    <ul>
                        <li>Coordinamento della sicurezza, pratiche per la Direttiva Europea Macchine, pratiche ADR, pratiche catastali.</li>
                    </ul>
                </li>
            </ul>

        <li><h3>2007 - 2010: ASSISTENTE DIREZIONE LAVORI - Aquagest Levante, Spagna</h3>
            <p>Gruppo multinazionale leader nel ciclo integrale dell'acqua acquisito dal GRUPPO SUEZ. Progettazione e costruzione di installazioni di acqua potabile, fognatura e reti pluviali, con ricostruzione dello sede stradale al termine.</p>
            <p>Attività svolte:</p>
            <ul>
                <li>Assistente Direzione Lavori 6 opere (tot 1,8km–6m€–10 lavoratori in cantiere)</li>
                <li>Project Manager 4 progetti (totale 500mt – 600.000€ – 10 lavoratori in cantiere)</li>
                <li>Responsabile del servizio di distribuzione dell'acqua potabile (comune di 12.000 abitanti)</li>
                <li>Progettista di supporto dei lavori futuri e feedback, nei tempi di fermo dei lavori</li>
            </ul>
        </li>

        <li><h3>2001 - 2006: INGEGNERE CIVILE - Autonomo, Italia</h3>
            <ul>
                <li>Realizzazione di progetti in ambito residenziale e stradale e, in collaborazione con altri studi, un ponte, un policlinico, una strada extraurbana e perizie giudiziarie;</li>
                <li>Contatto con i clienti, fattibilità legale, tecnica ed economica, progetto preliminare, definitivo ed esecutivo, calcoli specialistici, computo metrico, supervisione TQC, SAL, fattura finale; coordinamento della sicurezza, pratiche catastali.</li>
            </ul>
        </li>
      </ul>

      <h2><u><i>Formazione</i></u></h2>
      <ul>
        <li>2012: Omologazione del titolo di Ing. Civile in Spagna</li>
        <li>2005: Corso di Impenditoria e Business Plan | IAL | Oristano</li>
        <li>1999: Laurea Magistrale in Ing. Ambientale | Università di Cagliari | Cagliari</li>
      </ul>

      <h2><u><i>Skills</i></u></h2>
      <ul>
        <li>Curiosità</li>
        <li>Affidabilità</li>
        <li>Attenzione ai dettagli</li>
        <li>Team working</li>
      </ul>

      <h2><u><i>Lingue</i></u></h2>
      <ul>
        <li>Italiano: madrelingua</li>
        <li>Inglese: fluente (C1-2)</li>
        <li>Spagnolo: fluente (C2)</li>
        <li>Francese, Catalano: base</li>
      </ul>

      <h2><u><i>Altre info</i></u></h2>
      <ul>
        <li><Link to='/portfolio'>Portfolio</Link></li>
        <li>Automunito</li>
        <li>Patente B</li>
      </ul>
    </section>
  )
}
