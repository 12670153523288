import { useFetch } from "../hooks/useFetch"
import { Link, useNavigate } from 'react-router-dom'
import './Portfolio.css'

export default function PortfolioMain({language}) {
    const { data, isPending, error } = useFetch('/portfolioFiles/portfolio.json')
    const currentLang = language.lan.lan
    console.log(currentLang, "currentLang")
    console.log("data", data)
    
    const chapters = data ? data.chapters : []
    const navigate = useNavigate()

    return (
      <>
        {error && <p>Couldn't find any data</p>}
        {isPending && <p>Loading...</p>}
        {chapters && (
          <section className='portfolio-container'>
            <h1>PORTFOLIO</h1>
            <div className="portfolio">
            {chapters.map((chapter) => {
              return (
                <div 
                  key={chapter.ref} 
                  className='chapters-container'
                  onClick={() => navigate(`/portfolio/${chapter.ref}`)}
                >
                  <img 
                    src={`/portfolioFiles/${chapter.img_src}`} 
                    alt={chapter[currentLang]} 
                    className="portfolio-images"
                  />
                  <Link to={`/portfolio/${chapter.ref}`}>{chapter[currentLang]}</Link>
                </div>
              )
            })}
            </div>
          </section>
        )}
      </>
    )
}
