import { useState } from 'react'
import { useFetch } from "../hooks/useFetch"
import './Portfolio.css'
import NewSlider from '../components/Slider'
import { Link, useParams } from 'react-router-dom';

export default function Portfolio({language}) {
    const { id } = useParams();
    const { data, isPending, error } = useFetch(`/portfolioFiles/${id}`)
    const [isSliderOpen, setIsSliderOpen] = useState(false)
    const [currentSlide, setCurrentSlide] = useState(1)
    const currentLang = language.lan.lan
    console.log(currentLang, "currentLang")

    const handleClick = (index) => {
        setCurrentSlide(index)
        setIsSliderOpen(true)
    }
    const handleSlider = () => {
        setIsSliderOpen(false)
    }
    console.log("data", data)
    
    const images = data ? data.images : [];

    return (
        <>
        {!isSliderOpen && (
            <section className='portfolio-container'>
            <Link to={'/portfolio'}>{
                currentLang === 'ita'? "<< indietro" :
                currentLang === 'spa'? "<< atrás" : 
                "<< back"
            }</Link>
            <div className="portfolio">
                {images.map((img, index) => {
                    return (
                        <figure 
                            key={img.src} 
                            className="portfolio-images-container"
                            onClick={() => handleClick(index)} 
                        >
                            <img 
                                src={`/portfolioFiles/${img.src}`}  
                                className="portfolio-images" 
                                alt={img[currentLang]}
                                loading="lazy"
                            />
                            <figcaption>{img[currentLang]}</figcaption>
                        </figure>
                    )
                })} 
                
                {error && <p>Couldn't find any data</p>}
                {isPending && <p>Loading...</p>}            
            </div>
            </section>
            )}
        {isSliderOpen && <NewSlider
            currentSlide={currentSlide}
            handleSlider={handleSlider} 
            images={images}
            currentLang={currentLang}
        />}
        </>
    )
}
