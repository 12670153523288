import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { LancontextProvider } from './context/lancontext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LancontextProvider>
      <div className='global'>        
        <App />
      </div>
    </LancontextProvider>
  </React.StrictMode>
);
