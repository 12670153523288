import React, { useContext } from 'react';
import './App.css';
import { lancontext } from './context/lancontext';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Portfolio from './pages/Portfolio';
import PortfolioMain from './pages/PortfolioMain';
import Curriculum from './pages/Curriculum';
import Contacts from './pages/Contacts';
import MetaTag from './components/MetaTag';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const language = useContext(lancontext);

  console.log('App component language context:', language);

  if (!language) {
    return <div>Loading...</div>;
  }

  return (
    <HelmetProvider>
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path='/' element={<PageWrapper language={language} page="home" />} />
            <Route path='/portfolio' element={<PageWrapper language={language} page="portfolioMain" />} />
            <Route path='/portfolio/:id' element={<Portfolio language={language} />} />
            <Route path='/curriculum' element={<PageWrapper language={language} page="curriculum" />} />
            <Route path='/contacts' element={<PageWrapper language={language} page="contacts" />} />
          </Routes>
        </div>
      </BrowserRouter>
    </HelmetProvider>
  );
}

const PageWrapper = ({ language, page }) => {
  return (
    <>
      <MetaTag language={language.lan.lan} page={page} />
      {page === "home" && <Home language={language} />}
      {page === "portfolioMain" && <PortfolioMain language={language} />}
      {page === "curriculum" && <Curriculum language={language} />}
      {page === "contacts" && <Contacts language={language} />}
    </>
  );
};

export default App;
