import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#00B894" }}
      onClick={onClick}
    />
  );
}

export default function NewSlider({ 
  currentSlide, 
  handleSlider, 
  images, 
  currentLang 
}) {
  const sliderRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        sliderRef.current.slickNext();
      } else if (e.key === 'ArrowLeft') {
        sliderRef.current.slickPrev();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide,
    lazyLoad: true,
    adaptiveHeight: false,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />
  };

  return (
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        {images.map((img, index) => (
          <div key={index}>
            <FontAwesomeIcon icon={faCircleXmark} onClick={handleSlider} className="close-icon"/>
            <figcaption className="caption">{img[currentLang]}</figcaption>
            <img src={`/portfolioFiles/${img.src}`} alt={img.alt} className="slide-img"/>            
          </div>
        ))}
      </Slider>
    </div>
  );
}
